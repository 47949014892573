var site = site || {};

site.direction = site.direction || {};

(function ($) {
  var $header = $('.site-header');
  var modMaxWidth = 1024;
  var modMinWidth = 768;
  var scalables = [];

  function _verticallyCenterSlideAbstract() {
    // Only do this if browser doesn't support the modern, css-based way to
    // vertically center stuff.
    if (Modernizr.csstransforms) {
      return;
    }

    $('.slide_hero_module__abstract--default-y-pos').each(function () {
      var h = $(this).height();

      if (h) {
        $(this).height(h);
        $('.slide_hero_module__abstract__inner', this).css('marginTop', -(h / 2));
      }
    });
  }

  function _resize() {
    var $modules = $('.slide_hero_module');
    var wW = $(window).width();

    // If not between the min/max width parameters for scaling:
    if (wW >= modMaxWidth || wW < modMinWidth) {
      // Reset any styles that were scaled:
      _.each(scalables, function (item) {
        var styles = {};

        _.each(item.props, function (prop) {
          styles[prop] = '';
        });
        item.$el.css(styles);
      });

      _verticallyCenterSlideAbstract();

      return;
    }

    // Otherwise, scale each scalable property:
    _.each(scalables, function (item) {
      item.$el.each(function () {
        var styles = {};
        var $el = $(this);
        var originalValues = $el.data('scalable-original-values');

        _.each(item.props, function (prop) {
          if (_.isObject(originalValues) && !_.isUndefined(originalValues[prop])) {
            styles[prop] = (wW * originalValues[prop]) / modMaxWidth;
          }
        });

        $el.css(styles);
      });
    });

    _verticallyCenterSlideAbstract();
  }

  Drupal.behaviors.sliderHeroModule = {
    attach: function (context) {
      $('.slide_hero_module', context).each(function () {
        var $module = $(this);
        var $slickWrapper = $('.slide_hero_module__inner', $module);
        var $slides = $('.slide_hero_module__slide', $slickWrapper);
        var $nav = $('.slide_hero_module__nav', $module);
        // Store all the elements that need to scale, which properties need to
        // scale, and what their original values were for the _resize function.
        var modScalableElements = [
          {
            $el: $('.slide_hero_module__headline', $module),
            props: ['font-size', 'margin-bottom']
          },
          {
            $el: $('.slide_hero_module__description', $module),
            props: ['font-size', 'margin-bottom']
          },
          {
            $el: $('.hp_video_slide', $module),
            props: ['height']
          },
          // {
          //   $el: $('.slide_hero_module__link', $module),
          //   props: ['font-size']
          // },
          {
            $el: $('.slide_hero_module__abstract__inner', $module),
            props: ['padding-top', 'padding-right', 'padding-bottom', 'padding-left']
          }
        ];

        // Store the original values as whatever they currently are now:
        _.each(modScalableElements, function (item, index) {
          // Each property-value pair may be unique per element, so store them
          // on the element itself:
          item.$el.each(function () {
            var $el = $(this);
            var originalValues = $el.data('scalable-original-values') || {};

            _.each(item.props, function (prop, propIndex) {
              var originalValue = $el.css(item.props[propIndex]);

              if (_.isString(originalValue)) {
                originalValue = originalValue.replace('px', '') - 0;
              }

              originalValues[prop] = originalValue;
            });

            $el.data('scalable-original-values', originalValues);
          });
        });

        scalables = scalables.concat(modScalableElements);

        function _setStyles(index) {
          index = index || 0;
          var $slide = $slides.eq(index);

          // It can also control the color of the carousel arrows
          $module.toggleClass('slide_hero_module--light-arrows', !!$slide.data('light-arrows'));
        }
        // Nav Dots class present
        if ($('.js_nav_dots').length) {
          $slickWrapper.slick({
            rtl: site.direction.isRTL,
            dots: true,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            prevArrow: $('.slick-prev', $module),
            nextArrow: $('.slick-next', $module),
            onBeforeChange: function (slickSlider, slideIndex, futureSlideIndex) {
              _setStyles(futureSlideIndex);
            },
            onInit: function () {
              _setStyles();
            }
          });
        }
      }); // end each
    }
  };

  if (!Modernizr.csstransforms) {
    $(window).on('load', _resize);
  }

  $(window).on('resize-debounced', _resize);

  _resize();
})(jQuery);
